import React, { useState, useEffect, useRef } from "react";
import Index from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "primereact/tag";
import StudentIcon from "../../../../assets/icons/student.png";
import ParentEvents from "./ParentEvents";
import { getParentEvents, getParentStudentsAction, getParentUnpaidInvoices } from "../../../../redux/actions/ParentPortal/parentAction";
import Dropblack from "../../../../assets/icons/dropblack.png";
import pdfIcon from "../../../../assets/icons/pdf.png";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import { Menu } from "primereact/menu";
import { Charts } from "../../../../pages/Chart";
import ParentIcon from "../../../../assets/icons/person.png";
import CardHeader from "../../../../pages/CardHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getParentProfileApi } from "../../../../redux/actions/loginAction";
import types from "../../../../redux/types";
import { Messages } from "primereact/messages";

export default function Home(props) {
    let { getParentStudents } = useSelector((state) => state.parent);

    getParentStudents = getParentStudents?.map((item, i) => ({
        name: item.name,
        value: item.id,
    }));
    const msgs = useRef(null);

    const history = useHistory();

    useEffect(() => {
        dispatch(getParentProfileApi());
        return () => {
            dispatch({
                type: types.GET_PARENT_PROFILE,
                payload: {},
            });
        };
    }, []);

    const profile = useSelector((state) => state.login.getParentProfile);

    useEffect(() => {
        if (profile.is_update_password === 0) {
            history.push("/change-password");
        } else {
            history.push("/parent/dashboard");
        }
    }, [profile]);

    const dispatch = useDispatch();
    function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
    }

    const [selectedStudent, setSeletedStudent] = useState(null);

    useEffect(() => {
        if (selectedStudent) {
            console.log("selectedStudent==>", selectedStudent);
        }
    }, [selectedStudent]);

    // ✅ Get the first day of the current month
    const date2 = new Date();
    const firstDay = getFirstDayOfMonth(date2.getFullYear(), date2.getMonth());
    const [date, setDate] = useState(firstDay);

    useEffect(() => {
        dispatch(getParentStudentsAction());
    }, [dispatch]);

    useEffect(() => {
        if (getParentStudents.length) {
            setSeletedStudent(getParentStudents[0]);
        }
    }, [getParentStudents.length]);

    useEffect(() => {
        if (selectedStudent) {
            dispatch(getParentEvents(selectedStudent?.value, date));
        }
    }, [selectedStudent, date]);

    useEffect(() => {
        if (getParentStudents.length) {
            let _student = getParentStudents[0]?.name;
            setStudentName(_student);
        }
    }, [getParentStudents.length, dispatch, date]);

    useEffect(() => {
        dispatch(getParentUnpaidInvoices());
    }, []);

    const [studentName, setStudentName] = useState("");

    const parentDashboard = useSelector((state) => state?.parent.dashboard);

    const unpaidInvoices = useSelector((state) => state.parent.getUnpaidInvoices);

    useEffect(() => {
        if (unpaidInvoices?.invoices > 0) {
            if (msgs.current) {
                msgs.current.clear();
                msgs.current.show([
                    {
                        sticky: true,
                        className: "bg-yellow-200",
                        closable: false,
                        content: (
                            <div className="w-full flex justify-content-between align-items-center">
                                <div className="ml-2 text-left">
                                    You have {unpaidInvoices?.invoices} unpaid {unpaidInvoices?.invoices == 1 ? "invoice" : "invoices"} with a total amount of $
                                    {unpaidInvoices?.total_amount}
                                </div>
                                <div className="text-right ml-2 w-auto cursor-pointer " onClick={() => history.push({pathname:"/parent/account/?tab=invoices",state:{['openCard']:true}})}>
                                    <div className="shadow-1 border-round-sm p-2 px-3" style={{ background: "#ffe9a7" }}>
                                        Pay Now
                                    </div>

                                    {/* <ButtonComponent
                                        label="Pay Now"
                                        style={{ padding: "8px 12px", fontSize: "12px", backgroundColor: "#fff", color: "#000" }}
                                    /> */}
                                </div>
                            </div>
                        ),
                    },
                ]);
            }
        }
    }, [unpaidInvoices]);

    const studentList = getParentStudents?.map((item, i) => ({
        label: item.name,
        key: i + 1,
        command: () => {
            setSeletedStudent(item);
        },
    }));

    const studentFilter = useRef(null);
    return (
        <Index>
            <div className="p-4 ">
                <Messages ref={msgs} />
                <CardHeader icon={ParentIcon} heading="Parent Dashboard" />
                <div className="relative mb-3">
                    <div className="flex justify-content-between">
                        <ButtonComponent
                            className="flex align-items-center font-semibold bg-transparent border-none p-0 text-xs relative"
                            onClick={(e) => studentFilter.current.toggle(e)}
                        >
                            <img src={StudentIcon} alt="" className="bg-white p-2 border-round-lg" width={30} />
                            {<p className="font-bold text-dark">{selectedStudent?.name}</p>}
                            <img src={Dropblack} alt="" className="" width={15} />
                        </ButtonComponent>
                        <Menu model={studentList} popup ref={studentFilter} className="student-chart-view" />
                    </div>
                </div>
                <div className="grid mb-3">
                    <div className="md:col-6">
                        <div className=" bg-light-main border-round-lg p-3 h-100">
                            <h4 className="open-sans font-bold text-main">Attendance</h4>
                            <p className="open-sans text-black-alpha-90">In the last 90 days</p>
                            <div className="text-center">
                                <Charts present={parentDashboard?.total_presents} absent={parentDashboard?.total_absences} />
                            </div>
                        </div>
                    </div>
                </div>
                <ParentEvents parentDashboard={parentDashboard} date={date} setDate={setDate} />
                <div className="md:col-12 m-0 p-3 border-1 border-round-lg border-200 mb-2">
                    <h3 className="open-sans p-3 text-main font-semibold m-0">View Schedule</h3>
                    {parentDashboard?.schedule?.length > 0 ? (
                        <div className="border-1 border-round-lg shadow-sm border-200 mb-3 overflow-hidden open-sans">
                            <div className="bg-dark text-white p-3 font-bold">{/* {studentDashboard?.schedule?.schedule_title} */}</div>
                            <div className="shadow-sm flex">
                                {parentDashboard?.schedule?.map((attachment, index) => {
                                    return (
                                        <div className="col-3 p-3" key={index}>
                                            <a href={attachment?.file} target="_blank" rel="noreferrer">
                                                <img src={pdfIcon} alt="PDF FILE ICON" style={{ width: "22px" }}></img>
                                                <span className="text-dark">View File</span>
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="border border-300 border-round-lg h-8rem flex flex-column justify-content-center align-item-center mb-3">
                            <h5 className="text-400 open-sans font-semibold text-center m-0">No Schedule</h5>
                        </div>
                    )}
                </div>
                <div className="grid md:col-12 m-0 p-0">
                    <div className="md:col-12 px-0">
                        <div className="border-1 border-round-lg border-200 p-3">
                            <h3 className="open-sans text-main font-semibold mb-2">Recent Assignments</h3>
                            {parentDashboard?.recent_assignments?.length ? (
                                parentDashboard?.recent_assignments?.map((assignment, index) => {
                                    return (
                                        <div key={index} className="border border-300 border-round-lg mb-3 p-3">
                                            <h5 className="open-sans font-semibold  m-0 mb-2">
                                                {assignment?.category_name} By {assignment?.teacher_name}
                                                <Tag
                                                    severity="success"
                                                    value={assignment?.date}
                                                    className="ml-5 px-3 text-dark"
                                                    style={{ background: "var(--surface-300)" }}
                                                    rounded
                                                ></Tag>
                                            </h5>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: assignment?.assignment,
                                                }}
                                            ></p>
                                            {assignment?.online_resources?.map((res, index) => {
                                                return (
                                                    <p key={index}>
                                                        <a href={res.url} target="_blank" rel="noreferrer">
                                                            {res.url}
                                                        </a>
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="border border-300 border-round-lg h-8rem flex flex-column justify-content-center align-item-center mb-3">
                                    <h5 className="text-400 open-sans font-semibold text-center m-0">No Assignments</h5>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Index>
    );
}
