import types from "../../types";
import Constants from "../../../services/constant";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { api, multipartApi } from "../../../services/api";
import { showToast } from "../../../utils/showToast";
import { loadStateFn } from "../../../utils/localStorage";
import { removeEmpty, removeSpaces } from "../../../utils/commonFunctions";
import { getParentProfileApi } from "../loginAction";
import moment from "moment";

export const getParentStudentsAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_PARENT_STUDENTS);

    if (res.success) {
        dispatch({
            type: types.GET_PARENT_STUDENTS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const ViewStudentAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_STUDENT + id, {}, loadStateFn("jwt"));

    if (res.success) {
        dispatch({
            type: types.VIEW_STUDENT,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getParentMessageHistoryAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_MESSAGE_HISTORY);

    if (res.success) {
        dispatch({
            type: types.GET_MESSAGE_HISTORY,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const setStudentLogin = (visible, data, setData, setVisible) => async (dispatch) => {
    dispatch(showLoaderAction());
    const payload = {
        student_id: visible,
        user_name: data?.user_name,
        password: data?.password,
    };

    const res = await api("post", Constants.END_POINT.SET_STUDENT_LOGIN, payload);
    if (res.success) {
        dispatch(getParentStudentsAction());
        showToast([res.message], "success");
        setVisible(null);
        setData({
            user_name: "",
            password: "",
        });
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
export const getInvoices = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_INVOICES);

    if (res.success) {
        dispatch({
            type: types.GET_PARENT_INVOICES,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getParentEvents = (id, date) => async (dispatch) => {
    dispatch(showLoaderAction());

    const params = {
        date: moment(date).format("DD-MM-YYYY"),
    };

    const res = await api("get", Constants.END_POINT.PARENT_DASHBOARD + id, {}, loadStateFn("token"), params);
    console.log("werty", res);

    if (res.success) {
        dispatch({
            type: types.GET_PARENT_DASHBOARD,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getParentUnpaidInvoices = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.UNPAID_INVOICES, {}, loadStateFn("token"));

    if (res.success) {
        dispatch({
            type: types.UNPAID_INVOICES,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const uploadImages = async (images, type) => {
    const promises = images?.map(async (item) => {
        if (typeof item === "string") {
            return item;
        } else {
            const formData = new FormData();
            formData.append("file[0]", item);
            formData.append("type", type);

            const res = await multipartApi("post", Constants.END_POINT.UPLOAD_PARENT_FILES, formData);
            if (res.success && res.data) {
                return res.data[0];
            }
        }
    });

    const urls = await Promise.all(promises);
    return urls;
};

export const ParentSettings = (profile, history) => async (dispatch) => {
    dispatch(showLoaderAction());

    let payload = {
        ...profile,
        phone_number: profile.phone_number.replace(/[-()]/g, ""),
        home_phone: profile.home_phone.replace(/[-()]/g, ""),
        work_phone: profile.work_phone.replace(/[-()]/g, ""),
        email_lesson_reminders: profile.email_lesson_reminders ? 1 : 0,
        sms_lesson_reminders: profile.sms_lesson_reminders ? 1 : 0,
    };
    if (payload?.picture_url?.length) {
        payload.picture_url = await uploadImages(payload.picture_url, "parents/pictures");
        payload.picture_url = payload.picture_url[0];
        payload.is_picture_delete = 0;

        if (payload.picture_url.includes("http")) {
            delete payload.picture_url;
        }
    } else {
        payload.picture_url = "";
        payload.is_picture_delete = 1;
    }

    const final = removeEmpty(payload);

    const res = await api("post", Constants.END_POINT.PARENT_PROFILE_SETTINGS, final);
    if (res.success) {
        // history.goBack();
        showToast([res.message], "success");
        dispatch(getParentProfileApi());
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};

export const ChangeParentPassword = (data, setVisible) => async (dispatch) => {
    dispatch(showLoaderAction());
    const payload = {
        password: data?.current_password,
        new_password: data?.password,
    };

    const res = await api("post", Constants.END_POINT.CHANGE_PARENT_PASSWORD, payload);

    if (res.success) {
        showToast([res.message], "success");
        setVisible(false);
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
};

export const ParentCardAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_PARENT_CARDS, {});

    if (res.success) {
        dispatch({
            type: types.GET_PARENT_CARDS,
            payload: res.data,
        });
    } else {
        dispatch({
            type: types.GET_PARENT_CARDS,
            payload: [],
        });
    }

    dispatch(hideLoaderAction());
};

export const addCardAction = (cardDetail, setOpenCard) => async (dispatch) => {
    dispatch(showLoaderAction());

    const payload = {
        ...cardDetail,
        name: cardDetail?.card_holder_name,
        is_default: cardDetail?.is_default ? 1 : 0,
        card_number: removeSpaces(cardDetail?.card_number),
    };

    const res = await api("post", Constants.END_POINT.ADD_CARD, payload);

    if (res.success) {
        showToast([res.message], "success");
        setOpenCard(false);
        dispatch(ParentCardAction());
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
};

export const deleteCardAction = (id,next) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.DELETE_CARD + id, {});

    if (res.success) {
        showToast([res.message], "success");
        next();
        dispatch(ParentCardAction());
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
};
