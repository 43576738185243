import React from "react";
import { Link, useLocation } from "react-router-dom";
import LayoutData from "./Layout";

const SidebarIcons = () => {
    const location = useLocation();
    const activeLink = location?.pathname ==="/parent/account/"?"/parent/account/?tab=account":location?.pathname;
    const { menuItems } = LayoutData();
    return (
        <>
            <div className="shadow-1 overflow-scroll" style={{ height: "93vh" }}>
                <ul className="pt-3">
                    {menuItems.map((item, key) => (
                        <li
                            key={key}
                            className={
                                item.link === activeLink ? "p-3 side_menuitem text-dark active " : "p-3 side_menuitem text-dark "
                            }
                        >
                            <span className="flex justify-content-between align-items-center">
                                <Link
                                    to={item.link}
                                    className="no-underline side_menuitem text-dark open-sans flex align-items-center"
                                >
                                    {item.icon? <img src={item.icon} width={item.iconWidth} height={item.iconHeight} alt="" /> : null}
                                    &nbsp;
                                    <span className="tooltip-display shadow border-round-lg w-9rem z-3">
                                        {item.subMenu ? (
                                            <ul className="menu-show list-unstyled mt-2" key={key}>
                                                {item.subMenu?.map((subMenu, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Link
                                                                to={subMenu.link}
                                                                className="no-underline side_menuitem text-dark open-sans flex align-items-center "
                                                            >
                                                                {subMenu.name}
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : null}
                                    </span>
                                </Link>
                                {/* {item.subMenu ? (
                  <span onClick={() => toggleSubMenu(key)}>
                    <i
                      class={
                        isActiveSubMenu
                          ? "pi pi-chevron-up cursor-pointer"
                          : "pi pi-chevron-down cursor-pointer"
                      }
                    ></i>
                  </span>
                ) : null} */}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};
export default SidebarIcons;
