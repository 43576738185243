import { useState } from "react";
import Home from "../../../assets/icons/home.png";
import NewsPapericon from "../../../assets/icons/newspaper.png";
import Attendance from "../../../assets/icons/attandence.png";
import Calendar from "../../../assets/icons/calendermain.png";
import Laptop from "../../../assets/icons/laptop.png";
import WatchIcon from "../../../assets/icons/Watchred.png";
import Messageicon from "../../../assets/icons/messagehistory.png";
import CallIcon from "../../../assets/icons/Contactinfo.png";
import AccountInvoice from "../../../assets/icons/account-invoice.png";
import SettingIcon from "../../../assets/icons/setting.png";
import StudentIcon from "../../../assets/icons/student.png";

const Sidemenu = () => {
    const [isFullSidebar, setIsFullSidebar] = useState(true);
    const menuItems = [
        {
            name: "Home",
            link: "/parent/dashboard",
            icon: Home,
            iconWidth: "30px",
            iconHeight: "30px",
        },
        {
            name: "Calendar",
            link: "/parent/calendar",
            icon: Calendar,
            iconWidth: "29px",
            iconHeight: "29px",
        },
        {
            name: "Students",
            link: "/parent/students",
            icon: StudentIcon,
            iconWidth: "29px",
            iconHeight: "29px",
        },
        {
            name: " Teacher Contact Info",
            link: "/parent/contact",
            icon: CallIcon,
            iconWidth: "30px",
            iconHeight: "30px",
        },
        {
            name: "Account & Invoices",
            link: "/parent/account/",
            icon: AccountInvoice,
            iconWidth: "30px",
            iconHeight: "30px",
        },
        {
            name: "Attendance & Notes",
            link: "/parent/attandance-notes",
            icon: Attendance,
            iconWidth: "30px",
            iconHeight: "30px",
        },
        {
            name: "Assignments",
            link: "/parent/assignments",
            icon: Laptop,
            iconWidth: "30",
            iconHeight: "30px",
        },
        {
            name: "Practice Log",
            link: "/parent/practice-logs",
            icon: WatchIcon,
            iconWidth: "30px",
            iconHeight: "30px",
        },
        {
            name: "Message History",
            link: "/parent/message-history",
            icon: Messageicon,
            iconWidth: "30px",
            iconHeight: "30px",
        },
        {
            name: "News",
            link: "/parent/news",
            icon: NewsPapericon,
            iconWidth: "30px",
            iconHeight: "30px",
        },
        {
            name: "Settings",
            link: "/parent/profile-settings",
            icon: SettingIcon,
            iconWidth: "30px",
            iconHeight: "30px",
        },
    ];
    return { menuItems, isFullSidebar, setIsFullSidebar };
};
export default Sidemenu;
