import React, { useState } from "react";
import Index from "../../layout";
import SettingsIcon from "../../../../assets/icons/settingred.png";
import InputComponent from "../../../../components/Fields/InputField";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getParentProfileApi } from "../../../../redux/actions/loginAction";
import { Checkbox } from "primereact/checkbox";
import InputTextAreaComponent from "../../../../components/Fields/CustomInputTextArea";
import ChangePassword from "../../../../components/popup/ChangePassword";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import CardImg from "../../../../assets/icons/creditcard.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { confirmDelete, formattedPhoneNumber } from "../../../../utils/commonFunctions";
import InputMaskComponent from "../../../../components/Fields/InputMaskComponent";
import CheckboxComponent from "../../../../components/Fields/Checkbox";
import CustomImageInput from "../../../../components/Fields/CustomImageInput";
import { ParentCardAction, ParentSettings, deleteCardAction } from "../../../../redux/actions/ParentPortal/parentAction";
import AddNewCard from "./AddNewCard";
import Delete from "../../../../assets/icons/delete.png";
import autoPay from "../../../../assets/icons/Vector (2).png";
import CardHeader from "../../../../pages/CardHeader";

export default function ParentSetting() {
    const [visible, setVisible] = useState(false);
    const [makePayment, setMakePayment] = useState(false);
    const [openCard, setOpenCard] = useState(false);
    const [isExpire,setIsExpire] = useState(false)

    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(getParentProfileApi());
        dispatch(ParentCardAction());
    }, [dispatch]);
    const [cardId, setCardId] = useState(0);

    const parentProfile = useSelector((state) => state?.login?.getParentProfile);

    const cards = useSelector((state) => state?.parent?.getCards);
    useEffect(() => {
        if (cards?.card_details?.length > 0) {
            setCardId(cards?.default_card_id);
        }
    }, [cards]);

    const [profile, setProfile] = useState({
        first_name: "",
        last_name: "",
        email: "",
        address: "",
        home_phone: "",
        work_phone: "",
        phone_number: "",
        email_lesson_reminders: false,
        sms_lesson_reminders: false,
        picture_url: [],
        is_picture_delete: 0,
    });
    const onDelete = (event, position) => {
        confirmDelete(
            () => {
                dispatch(deleteCardAction(event?.card_id,()=>{}));
            },
            "Do you want to delete this Card?",
            position
        );
    };

    useEffect(() => {
        setProfile({
            first_name: parentProfile?.first_name,
            last_name: parentProfile?.last_name,
            email: parentProfile?.email,
            address: parentProfile?.address,
            phone_number: parentProfile.phone_number ? formattedPhoneNumber(parentProfile.phone_number) : "",
            home_phone: parentProfile.home_phone ? formattedPhoneNumber(parentProfile.home_phone) : "",
            work_phone: parentProfile.work_phone ? formattedPhoneNumber(parentProfile.work_phone) : "",
            email_lesson_reminders: parentProfile?.email_lesson_reminders ? true : false,
            sms_lesson_reminders: parentProfile?.sms_lesson_reminders ? true : false,
            is_picture_delete: 0,
            picture_url: parentProfile.picture ? [parentProfile.picture] : [],
        });
    }, [parentProfile]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile((prev) => ({ ...prev, [name]: value }));
    };
    const handleCheck = (name, e) => {
        setProfile({ ...profile, [name]: !profile[name] });
    };
    const profilehandleChange = ({ name, value }) => {
        setProfile((prev) => ({ ...prev, [name]: value }));
    };
    const deleteTemplate = (col) => {
        return (
            <>
                <img alt="imh" src={Delete} width="15px" onClick={() => onDelete(col)} />
            </>
        );
    };
    const autoPayTemplate = (rowData) => {
        return <>{cardId === rowData?.card_id ? <img alt="alk" src={autoPay} width="15px" /> : null}</>;
    };

    return (
        <Index>
            <div className="p-4">
                <CardHeader icon={SettingsIcon} heading="My Profile" />
            </div>
            <div className="shadow-sm border-round-lg mx-3 p-3">
                <div className="grid">
                    <CustomImageInput
                        data={profile}
                        onFilesChange={profilehandleChange}
                        name="picture_url"
                        editable={true}
                        view={false}
                        removeable
                    ></CustomImageInput>
                    <div className="col grid justify-content-between mr-3">
                        <InputComponent
                            extraClassName="md:col-6"
                            label="First Name"
                            name="first_name"
                            size="md:col-6"
                            placeholder="Enter your first name"
                            required
                            value={profile?.first_name}
                            onChange={handleChange}
                            // errorMessage={err?.first_name}
                        />
                        <InputComponent
                            extraClassName="md:col-6"
                            label="Last Name"
                            name="last_name"
                            size="md:col-6"
                            placeholder="Enter your last name"
                            required
                            value={profile?.last_name}
                            onChange={handleChange}
                        />
                        <InputComponent
                            extraClassName="md:col-6"
                            label="Email"
                            name="email"
                            size="md:col-6"
                            placeholder="Enter your last name"
                            required
                            value={profile?.email}
                            disabled
                        />
                        <div className="md:col-6"></div>
                        <InputTextAreaComponent
                            label="Address"
                            name="address"
                            extraClassName="md:col-12"
                            placeholder="(optional)"
                            value={profile?.address}
                            onChange={handleChange}
                        />
                        <div className="md:col-6">
                            <InputMaskComponent
                                label="Home phone"
                                labelClassName="absolute text-xs text-500 open-sans  bg-white form-label"
                                name="home_phone"
                                id="home_phone"
                                mask="(999)999-9999"
                                placeholder="___-___-____"
                                extraClassName=" relative"
                                inputClass="w-full border-1 p-3 border-round-lg border-400"
                                value={profile?.home_phone}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="md:col-6">
                            <InputMaskComponent
                                label="Work phone"
                                labelClassName="absolute text-xs text-500 open-sans  bg-white form-label"
                                name="work_phone"
                                id="work_phone"
                                mask="(999)999-9999"
                                placeholder="___-___-____"
                                extraClassName=" relative"
                                inputClass="w-full border-1 p-3 border-round-lg border-400"
                                value={profile?.work_phone}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="md:col-6">
                            <InputMaskComponent
                                label="Mobile phone"
                                labelClassName="absolute text-xs text-500 open-sans  bg-white form-label"
                                name="phone_number"
                                id="phone_number"
                                mask="(999)999-9999"
                                placeholder="___-___-____"
                                extraClassName=" relative"
                                inputClass="w-full border-1 p-3 border-round-lg border-400"
                                required
                                value={profile?.phone_number}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid mt-4 p-2">
                    <div className="lg:w-18rem lg:col-3 xl:col-3 p-1">
                        <label className="font-semibold">Reminders</label>
                    </div>
                    <div className="col">
                        <div className="flex align-items-center mb-2">
                            <CheckboxComponent
                                inputId="email_lesson_reminder"
                                name="email_lesson_reminder"
                                checked={profile?.email_lesson_reminders}
                                onChange={() => handleCheck("email_lesson_reminders")}
                            />
                            <label htmlFor="email_lesson_reminder" className="ml-2">
                                Receive automated email reminder
                            </label>
                        </div>
                        <div className="flex align-items-center mb-3">
                            <Checkbox
                                inputId="sms_lesson_reminders"
                                name="sms_lesson_reminders"
                                checked={profile?.sms_lesson_reminders}
                                onChange={() => handleCheck("sms_lesson_reminders")}
                            />
                            <label htmlFor="sms_lesson_reminders" className="ml-2">
                                Receive automated SMS reminder
                            </label>
                        </div>
                        <ButtonComponent label="Change Password" onClick={() => setVisible(true)} />
                        <ButtonComponent
                            label="Manage Payment Methods"
                            className="surface-800 text-white border-0 ml-2"
                            onClick={() => setMakePayment(true)}
                        />
                    </div>
                </div>
                <div className="grid mt-3">
                    <div className="lg:w-18rem lg:col-3 xl:col-3 p-1 "></div>
                    <div className="md:col-6">
                        <ButtonComponent label="Cancel" className="surface-300 border-300" />
                        <ButtonComponent
                            label="Save"
                            className="ml-2"
                            onClick={() => {
                                dispatch(ParentSettings(profile, history));
                            }}
                        />
                    </div>
                </div>
            </div>
            {visible && <ChangePassword visible={visible} setVisible={setVisible} role="parent" />}
            <Dialog
                header="Manage Payment Methods"
                visible={makePayment}
                // style={{ width: "50vw" }}
                onHide={() => setMakePayment(false)}
            >
                <DataTable
                    className="mb-2"
                    tableStyle={{ minWidth: "50rem" }}
                    value={cards?.card_details?.length && cards?.card_details}
                >
                    <Column field="card_type" header="Type"></Column>
                    <Column field="card_number" header="Account Number"></Column>
                    <Column field="auto_pay" body={autoPayTemplate} header="Auto-Pay"></Column>
                    <Column body={deleteTemplate} header="Action"></Column>
                </DataTable>
                <Button className="bg-light-main text-sm font-semibold" onClick={() => setOpenCard(true)}>
                    <img src={CardImg} alt="" width={20} />
                    <span className="ml-2">Add New Card</span>
                </Button>

                <Dialog
                    header="Add New Card"
                    className="assign-student border-round-lg overflow-hidden "
                    visible={openCard}
                    style={{ width: "48vw" }}
                    draggable={false}
                    resizable={false}
                    onHide={() => setOpenCard(false)}
                >
                    <AddNewCard openCard={openCard} setOpenCard={setOpenCard}isExpire={isExpire}setIsExpire={setIsExpire} />
                </Dialog>
            </Dialog>
        </Index>
    );
}
